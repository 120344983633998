import { type Readable, type StartStopNotifier, type Writable, writable } from "svelte/store";

export interface Loaded {
	loaded: boolean;
}

type LocalStorageStore<Value> = Readable<Value & Loaded> & Omit<Writable<Value>, keyof Readable<Value & Loaded>>;

export function localStorageWritable<Value extends object>(
	localStorageKey: string,
	initialValue: Value,
	start?: StartStopNotifier<Value & Loaded> | undefined,
): LocalStorageStore<Value> {
	const loaded = typeof window !== "undefined";
	const initial: Value & Loaded = {
		...initialValue,
		loaded,
	};
	if (typeof localStorage === "undefined") {
		return writable(initial, start);
	}

	const storedValue = localStorage.getItem(localStorageKey);

	const {
		subscribe,
		set: _set,
		update: _update,
	} = writable(storedValue !== null ? { ...(JSON.parse(storedValue) as Value), loaded } : initial, start);

	function saveValueToLocalStorage(value: Value): void {
		localStorage.setItem(localStorageKey, JSON.stringify(value));
	}

	if (loaded) {
		window.addEventListener("storage", () => {
			const storedValue = localStorage.getItem(localStorageKey);
			if (storedValue) {
				_set({
					...(JSON.parse(storedValue) as Value),
					loaded,
				});
			} else {
				_set(initial);
			}
		});
	}

	return {
		subscribe,
		set: (value: Value): void => {
			saveValueToLocalStorage(value);
			_set({
				...value,
				loaded,
			});
		},
		update: (updater): void => {
			_update(({ loaded: _, ...value }) => {
				const next = updater(value as Value);
				saveValueToLocalStorage(next);
				return {
					...next,
					loaded,
				};
			});
		},
	};
}
