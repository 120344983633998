export enum PaymentType {
	GoPayPaymentCard = "GoPayPaymentCard",
	GoPayGPay = "GoPayGPay",
	GoPayApplePay = "GoPayApplePay",
	OnDelivery = "OnDelivery",
	// GoPayBankAccount = "GoPayBankAccount",
}

export const paymentTypeNameMap: Record<PaymentType, string> = {
	[PaymentType.GoPayPaymentCard]: "Kartou",
	[PaymentType.GoPayGPay]: "Google Pay",
	[PaymentType.GoPayApplePay]: "Apple Pay",
	[PaymentType.OnDelivery]: "Dobírkou",
	// [PaymentType.GoPayBankAccount]: "Bankovním převodem",
};

export const paymentTypeLongNameMap: Record<PaymentType, string> = {
	[PaymentType.GoPayPaymentCard]: "Platební kartou",
	[PaymentType.GoPayGPay]: "Google Pay",
	[PaymentType.GoPayApplePay]: "Apple Pay",
	[PaymentType.OnDelivery]: "Platba na místě hotově nebo kartou",
	// [PaymentType.GoPayBankAccount]: "Bankovním převodem",
};
