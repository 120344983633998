import type { ButtonProps, ButtonVariant } from "./ButtonProps.js";

export function generateButtonClasses({ variant, disabled }: ButtonProps): string {
	const btnVariants: Record<ButtonVariant, string> = {
		primary:
			"group bg-primaryRed border-primaryRed hover:text-primaryRed focus:text-primaryRed active:text-primaryRed text-white hover:bg-white w-fit focus:bg-white w-fit active:bg-white w-fit",
		secondary:
			"group bg-white border-black text-black hover:border-primaryRed focus:border-primaryRed active:border-primaryRed hover:text-primaryRed w-fit focus:text-primaryRed w-fit active:text-primaryRed w-fit",
		secondaryIcon:
			"group max-md:h-8 max-md:w-8 max-md:!p-0 flex-row-reverse bg-white border-black text-black hover:border-primaryRed focus:border-primaryRed active:border-primaryRed hover:text-primaryRed focus:text-primaryRed active:text-primaryRed w-fit",
		transparent: "group text-black flex gap-2 items-center",
		number:
			"group bg-secondaryBlack-200 border-transparent text-secondaryBlack md:rounded-[1.125rem] rounded-full flex font-semibold items-center hover:bg-secondaryBlack-300 focus:bg-secondaryBlack-300 active:bg-secondaryBlack-300 transition-all duration-300 justify-center w-6 flex-shrink-0 h-6",
	};
	const variantClass = btnVariants[variant ?? "primary"];

	const disabledClass = disabled && "opacity-50 pointer-events-none";

	if (variant !== "transparent" && variant !== "number") {
		return [
			"md:rounded-[1.125rem] rounded-full flex gap-[6px] items-center no-underline border px-[1.125rem] leading-5 py-[0.4375rem] font-semibold transition-all duration-300",
			variantClass,
			disabledClass,
		]
			.filter(Boolean)
			.join(" ");
	}

	return [variantClass, disabledClass].filter(Boolean).join(" ");
}
