import { assertExhaustiveSwitchValue } from "../../utils/assertExhaustiveSwitchValue.js";
import { ProductOption, type ProductOptionValueTypes } from "../ProductOption.js";

export function isProductOptionUsed<Option extends ProductOption>(
	option: Option,
	value: ProductOptionValueTypes[Option],
): value is Exclude<ProductOptionValueTypes[Option], undefined> {
	switch (option) {
		case ProductOption.CreamColor:
		case ProductOption.LyingPhoto:
		case ProductOption.Macaroons:
			return value !== undefined;
		case ProductOption.Numbers:
		case ProductOption.Labels:
		case ProductOption.StandingPhotos:
		case ProductOption.Figurines:
			return Array.isArray(value) && value.length > 0;
		case ProductOption.ExtraFruit:
		case ProductOption.GlutenFreeVariant:
		case ProductOption.LactoseFreeVariant:
		case ProductOption.Meringues:
			return value === true;
		default:
			assertExhaustiveSwitchValue(option);
	}
}
