import type { OrderProduct } from "../OrderProduct.js";

export function getSpreadedProducts(products: OrderProduct[]): OrderProduct[] {
	const spreadedProducts: OrderProduct[] = [];

	for (const orderProduct of products) {
		if (!orderProduct.product.producedOnePieceAtATime) {
			spreadedProducts.push(orderProduct);
			continue;
		}

		for (let i = 0; i < orderProduct.quantity; i++) {
			spreadedProducts.push({
				...orderProduct,
				quantity: 1,
			});
		}
	}

	return spreadedProducts;
}
