import { PaymentType } from "../../../../core/schema/PaymentType.js";
import { getCantPayOnDeliveryReasons } from "../../../../core/schema/utils/getCantPayOnDeliveryReasons.js";
import type { Cart } from "./Cart.js";
import { CartStep, cartStepOrderMap } from "./CartStep.js";

export function cartWithAppliedProperties<T extends Cart>(cart: T): T {
	const currentCartStep = cartStepOrderMap[cart.step];

	const cantPayOnDeliveryReasons = getCantPayOnDeliveryReasons(cart.order);
	if (cantPayOnDeliveryReasons.length > 0) {
		if (cart.order.payment?.type === PaymentType.OnDelivery) {
			cart.order.payment.type = undefined;

			if (currentCartStep > cartStepOrderMap[CartStep.PaymentDelivery]) {
				cart.step = CartStep.PaymentDelivery;
			}
		}
	}

	return {
		...cart,
		properties: {
			cantPayOnDeliveryReasons,
		},
	};
}
