import { type Readable, get } from "svelte/store";
import type { Order } from "../../../../core/schema/Order.js";
import type { OrderProductOptions } from "../../../../core/schema/OrderProductOptions.js";
import type { ProductForCustomer } from "../../../../core/schema/Product.js";
import { getPriceForProductOfSize } from "../../../../core/schema/utils/getPriceForProductOfSize.js";
import { isSameProduct } from "../../../../core/schema/utils/isSameProduct.js";
import { transformProductToOrderProduct } from "../../../../core/schema/utils/transformProductToOrderProduct.js";
import type { DeepPartial } from "../../../../core/utils/DeepPartial.js";
import { deepMerge } from "../../../../core/utils/deepMerge.js";
import { localStorageWritable } from "../localStorageWritable.js";
import type { AddResponse } from "./AddResponse.js";
import type { Cart, CartInStep } from "./Cart.js";
import { CartStep, cartStepOrderMap, getNextStep } from "./CartStep.js";
import { cartVersion } from "./cartVersion.js";
import { cartWithAppliedProperties } from "./cartWithAppliedProperties.js";
import { ensureCartIsInCorrectStep } from "./ensureCartIsInCorrectStep.js";
import type { FieldsToFillInPerStep } from "./fieldsToFillInPerStep.js";

const initialCart: Cart = cartWithAppliedProperties({
	productsInOrder: [],
	properties: {
		cantPayOnDeliveryReasons: [],
	},
	order: {
		products: [],
		vouchers: [],
	},
	step: CartStep.Overview,
});

const localStorageKey = `cart-${cartVersion}`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createCart() {
	const store = localStorageWritable<Cart>(localStorageKey, initialCart);
	const { subscribe, set, update: _update } = store;

	/*
	 * Custom update function that applies properties to the cart and ensures that the cart is in the correct step (depending on the fields that must be filled in per step).
	 * Also provides a way to run a callback after the cart has been updated.
	 */
	async function update<T extends Cart>(updater: (value: T) => Promise<T> | T): Promise<T> {
		const current = get(store);
		const afterUpdate = await updater(current as unknown as T);
		return new Promise((resolve) => {
			_update(() => {
				const cartWithProperties = cartWithAppliedProperties(afterUpdate);
				const cartWithEnsuredCorrectStep = ensureCartIsInCorrectStep(cartWithProperties);

				resolve(cartWithEnsuredCorrectStep);
				return cartWithEnsuredCorrectStep;
			});
		});
	}

	async function removeItem(productIndex: number): Promise<Cart> {
		return await update((cart) => {
			const orderProductToRemove = cart.order.products[productIndex];
			if (!orderProductToRemove) {
				throw new Error("Index is out of bounds");
			}

			cart.order.products.splice(productIndex, 1);

			const noMoreProductOfIdInCart = !cart.order.products.some(
				({ product: { id } }) => id === orderProductToRemove.product.id,
			);
			if (noMoreProductOfIdInCart) {
				cart.productsInOrder = cart.productsInOrder.filter(({ id }) => id !== orderProductToRemove.product.id);
			}

			if (cart.order.products.length === 0) {
				return initialCart;
			}

			return cart;
		});
	}

	async function setQuantity(productIndex: number, quantity: number): Promise<Cart> {
		return await update(async (cart) => {
			if (quantity > 0) {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				orderProduct.quantity = quantity;

				return cart;
			} else {
				return await removeItem(productIndex);
			}
		});
	}

	async function saveFields<CurrentCart extends Cart = Cart>(
		fields: DeepPartial<CurrentCart["order"]>,
	): Promise<CurrentCart> {
		return await update<CurrentCart>((cart) => {
			cart.order = deepMerge(cart.order, fields);
			return cart;
		});
	}

	return {
		subscribe,
		setQuantity,
		saveFields,
		async advanceToNextStep<Step extends CartStep>(
			currentStep: Step,
			fields: Pick<CartInStep<Step>["order"], FieldsToFillInPerStep<Step>>,
			onAdvance?: (cart: Cart) => void,
		): Promise<boolean> {
			await saveFields(fields);

			let advanced = false;
			let canAdvance = false;

			const updated = await update((cart) => {
				if (cart.order.products.length === 0) {
					return cart;
				}

				const cartStepIndex = cartStepOrderMap[cart.step];
				const nextStep = getNextStep(currentStep);
				const nextStepIndex = cartStepOrderMap[nextStep];
				if (cartStepIndex < nextStepIndex) {
					cart.step = nextStep;
					advanced = true;
				}

				canAdvance = true;
				return cart;
			});

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (advanced) {
				onAdvance?.(updated);
			}

			return canAdvance;
		},
		async increaseQuantity(productIndex: number): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				return setQuantity(productIndex, orderProduct.quantity + 1);
			});
		},
		async decreaseQuantity(productIndex: number): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				return setQuantity(productIndex, orderProduct.quantity - 1);
			});
		},
		async addItem(
			product: ProductForCustomer,
			quantity: number,
			minimumQuantity = 1,
			options?: OrderProductOptions,
		): Promise<AddResponse> {
			let response = {} as AddResponse;
			await update((cart) => {
				const productToAdd = transformProductToOrderProduct(crypto.randomUUID(), product, quantity, options);
				const isFirstProductOfId = !cart.order.products.some(({ product: { id } }) => id === productToAdd.product.id);
				if (isFirstProductOfId) {
					cart.productsInOrder.push(product);
				}
				const existingProduct = cart.order.products.find((orderProduct) => isSameProduct(orderProduct, productToAdd));

				if (existingProduct) {
					const oldQuantity = existingProduct.quantity;
					existingProduct.quantity = Math.max(existingProduct.quantity + quantity, minimumQuantity);
					response = {
						type: "increment",
						product: productToAdd,
						quantity: existingProduct.quantity - oldQuantity,
					};
				} else {
					if (productToAdd.quantity < minimumQuantity) {
						productToAdd.quantity = minimumQuantity;
					}
					cart.order.products.push(productToAdd);
					response = {
						type: "add",
						product: productToAdd,
						quantity: productToAdd.quantity,
					};
				}

				return cart;
			});
			return response;
		},
		async updateItemOptions(productIndex: number, optionsToUpdate: Partial<OrderProductOptions>): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				// Cakes always have options, other products never. So this should be safe.
				if (orderProduct.options) {
					orderProduct.options = {
						...orderProduct.options,
						...optionsToUpdate,
					};
				}

				const product = cart.productsInOrder.find(({ id }) => id === orderProduct.product.id);
				if (!product) {
					throw new Error("Detail for product in cart not found.");
				}
				const price = getPriceForProductOfSize(product, orderProduct.options?.size);
				if (!price) {
					throw new Error("Price of product not found");
				}
				orderProduct.product.price = price;

				return cart;
			});
		},
		async updateItem(productIndex: number, product: ProductForCustomer): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				const productInOrderIndex = cart.productsInOrder.findIndex(({ id }) => id === product.id);
				if (productInOrderIndex === -1) {
					throw new Error("Product not found in productsInOrder");
				}
				cart.productsInOrder[productInOrderIndex] = product;
				cart.order.products[productIndex] = transformProductToOrderProduct(
					orderProduct.id,
					product,
					orderProduct.quantity,
					orderProduct.options,
				);

				return cart;
			});
		},
		async markAsSent(order: Order): Promise<Cart> {
			return await update((cart) => {
				if (cart.step !== CartStep.ReadyToSend) {
					throw new Error("The cart is not ready to be sent");
				}
				cart.sentOrder = order;
				return cart;
			});
		},
		removeItem,
		clear(): void {
			set(initialCart);
		},
	} satisfies Readable<Cart> & Record<string, unknown>;
}

export const cartStore = createCart();
