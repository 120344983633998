export enum OrderProductStatus {
	ToDo = "ToDo",
	AssignedToConfectioner = "AssignedToConfectioner",
	Ready = "Ready",
}

export const orderProductStatusNameMap: Record<OrderProductStatus, string> = {
	[OrderProductStatus.ToDo]: "TODO",
	[OrderProductStatus.AssignedToConfectioner]: "Zadáno cukráři",
	[OrderProductStatus.Ready]: "Vyrobeno",
};
