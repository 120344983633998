import type { CreamColor } from "./CreamColor.js";
import type { ProductFigurine } from "./ProductFigurine.js";
import type { ProductLabel } from "./ProductLabel.js";
import type { ProductMacaroons } from "./ProductMacaroons.js";
import type { ProductNumber } from "./ProductNumber.js";
import type { ProductPhoto } from "./ProductPhoto.js";

/*
 * When adding a new option, make sure:
 * - Admin:
 * 	- Configurator has it
 * 	- Order detail in products tabs shows it
 * 	- Product cards show it (if desired to be separate from decorations)
 * 	- Product modal in orders branch module shows it
 * 	- Komando Ticket shows it
 * - Web: (if not restricted)
 * 	- Configurator has it
 * 	- Cart shows it
 * - Emails shows it
 */
export enum ProductOption {
	Numbers = "Numbers",
	ExtraFruit = "ExtraFruit",
	GlutenFreeVariant = "GlutenFreeVariant",
	LactoseFreeVariant = "LactoseFreeVariant",
	Labels = "Labels",
	LyingPhoto = "LyingPhoto",
	StandingPhotos = "StandingPhotos",
	Meringues = "Meringues",
	CreamColor = "CreamColor",
	Macaroons = "Macaroons",
	Figurines = "Figurines",
}

export const restrictedProductOptions = [ProductOption.Figurines] satisfies ProductOption[];

export interface ProductOptionValueTypes {
	[ProductOption.Numbers]: ProductNumber[];
	[ProductOption.ExtraFruit]: boolean;
	[ProductOption.GlutenFreeVariant]: boolean;
	[ProductOption.LactoseFreeVariant]: boolean;
	[ProductOption.Labels]: ProductLabel[];
	[ProductOption.LyingPhoto]?: ProductPhoto;
	[ProductOption.StandingPhotos]: ProductPhoto[];
	[ProductOption.Meringues]: boolean;
	[ProductOption.CreamColor]?: CreamColor;
	[ProductOption.Macaroons]?: ProductMacaroons;
	[ProductOption.Figurines]: ProductFigurine[];
}

export const productOptionKeyMap = {
	[ProductOption.Numbers]: "numbers" as const,
	[ProductOption.ExtraFruit]: "extraFruit" as const,
	[ProductOption.GlutenFreeVariant]: "glutenFree" as const,
	[ProductOption.LactoseFreeVariant]: "lactoseFree" as const,
	[ProductOption.Labels]: "labels" as const,
	[ProductOption.LyingPhoto]: "lyingPhoto" as const,
	[ProductOption.StandingPhotos]: "standingPhotos" as const,
	[ProductOption.Meringues]: "meringues" as const,
	[ProductOption.CreamColor]: "creamColor" as const,
	[ProductOption.Macaroons]: "macaroons" as const,
	[ProductOption.Figurines]: "figurines" as const,
} satisfies Record<ProductOption, string>;

export const productOptionKeyReversedMap = Object.fromEntries(
	Object.entries(productOptionKeyMap).map(([key, value]) => [value, key] as const),
) as {
	// eslint-disable-next-line no-use-before-define
	[Option in keyof typeof productOptionKeyMap as (typeof productOptionKeyMap)[Option]]: Option;
};
