import { roundToPlaces } from "../../utils/roundToPlaces.js";
import type { B2cOrderCreateInput, B2cOrderEditInput, Order } from "../Order.js";
import type { PriceLevel } from "../PriceLevel.js";
import { getPriceOfOrderProduct } from "./getPriceOfOrderProduct.js";
import { getSpreadedProducts } from "./getSpreadedProducts.js";

export function getPriceOfOrder(
	{ products }: Pick<Order | B2cOrderCreateInput | B2cOrderEditInput, "products">,
	priceLevel: PriceLevel,
): Order["price"] {
	let withTax = 0;
	let withoutTax = 0;

	/*
	 * Spread products before calculating the price, otherwise price is not calculated precisely.
	 */
	const spreadedProducts = getSpreadedProducts(products);

	for (const product of spreadedProducts) {
		const { withTax: productWithTax, withoutTax: productWithoutTax } = getPriceOfOrderProduct(product);
		withTax += productWithTax;
		withoutTax += productWithoutTax;
	}

	return {
		deliveryPrice: 0, // TODO Calculate delivery price
		totalPrice: {
			priceLevel,
			withoutTax: roundToPlaces(withoutTax, 2),
			withTax,
		},
	};
}
