import { CantPayOnDeliveryReason } from "../CantPayOnDeliveryReason.js";
import type { B2cOrderCreateInput, B2cOrderEditInput, Order } from "../Order.js";
import { PriceLevel } from "../PriceLevel.js";
import { ProductOption, productOptionKeyMap } from "../ProductOption.js";
import { maximumPriceForPaymentOnDelivery } from "../maximumPriceForPaymentOnDelivery.js";
import { getPriceOfOrder } from "./getPriceOfOrder.js";
import { isProductOptionUsed } from "./isProductOptionUsed.js";

export function getCantPayOnDeliveryReasons(
	order: Pick<Order | B2cOrderCreateInput | B2cOrderEditInput, "products">,
): CantPayOnDeliveryReason[] {
	const cartPrice = getPriceOfOrder(order, PriceLevel.Basic);

	const cantPayOnDeliveryReasons: CantPayOnDeliveryReason[] = [];

	const totalPriceTooHigh = cartPrice.totalPrice.withTax > maximumPriceForPaymentOnDelivery;
	if (totalPriceTooHigh) {
		cantPayOnDeliveryReasons.push(CantPayOnDeliveryReason.PriceTooHigh);
	}

	const someProductHasOption = order.products.some((product) =>
		Object.values(ProductOption).some((option) =>
			isProductOptionUsed(option, product.options?.[productOptionKeyMap[option]]),
		),
	);
	if (someProductHasOption) {
		cantPayOnDeliveryReasons.push(CantPayOnDeliveryReason.ProductsHaveOptions);
	}

	return cantPayOnDeliveryReasons;
}
