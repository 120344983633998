import type { Locale } from "../../core/schema/Locale.js";
import type { TenantId } from "../../core/schema/Tenant.js";

export const globalSettingsContextKey = "globalSettings";

export interface GlobalSettings {
	VERSION: string;
	TENANT_ID: TenantId;
	TENANT_LOCALE: Locale;
	ENV: string;
	DOMAIN: string;
	ENV_DOMAIN: string;
	ROBOTS: "index" | "noindex";
	STORAGE_URL: string;
	UPLOAD_BUCKET: string;
	UPLOAD_URL: string;
	WEB_URL: string;
	AWS_REGION: string;
	AWS_USER_POOL_ID: string;
	AWS_IDENTITY_POOL_ID: string;
	AWS_USER_POOL_CLIENT_ID: string;
	PUBLIC_GRAPHQL_ENDPOINT: string;
	COOKIES: string;
	GOOGLE_ANALYTICS: string;
	SMARTFORM: string;
	SENTRY_DSN: string;
	SMARTLOOK_PROJECT_KEY: string;
	SMARTLOOK_REGION: string;
}
