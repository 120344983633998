<script lang="ts">
	import Button from "./Button.svelte";
	import logo from "../../../core/assets/logo.svg";
	import Icon from "./Icon.svelte";
	import menuIcon from "../../../core/assets/icons/macaroon.svg?raw";
	import type { MenuItem } from "../../../core/schema/MenuItem.js";
	import { cartStore } from "../stores/cart/cartStore.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { cartPathMap } from "../../../core/schema/paths/cartPathMap.js";
	import Link from "./Link.svelte";
	// import { signUpPathMap } from "../../../core/schema/signUpPathMap.js";
	import basket from "../../../core/assets/icons/basket.svg?raw";
	import { appName } from "../../../core/schema/appName";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	// import person from "../../../core/assets/icons/person.svg?raw";
	import Drawer from "./Drawer.svelte";
	import Announcement from "./Announcement.svelte";
	import MobileMenu from "./Menu/MobileMenu.svelte";
	import DesktopMenu from "./Menu/DesktopMenu.svelte";
	import { showHeaderStore } from "../stores/showHeaderStore.js";

	export let menu: MenuItem[];
	export let announcement: string | undefined = undefined;

	const { TENANT_LOCALE } = getGlobalSettings();

	let isMenuOpen = false;

	function toggleMenu(): void {
		isMenuOpen = !isMenuOpen;
	}

	let header: HTMLElement | null;
	let lastScrollTop = 0;
	let windowScrollY = 0;
	let scrollTop: number;
	let headerOnScroll: string;

	function onScroll(): void {
		const { scrollY } = window;
		scrollTop = scrollY;
	}

	$: {
		if (header) {
			if (scrollTop >= lastScrollTop && !$showHeaderStore) {
				headerOnScroll = "-translate-y-full";
			} else {
				headerOnScroll = "translate-y-0";
			}
			lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
		}
	}
</script>

<svelte:window bind:scrollY={windowScrollY} on:scroll={onScroll} />

{#if announcement}
	<Announcement {announcement} />
{/if}

{#if menu.length > 0}
	<Drawer bind:isOpen={isMenuOpen} position="left" dontShowCloseButton>
		<MobileMenu bind:isOpen={isMenuOpen} {menu} />
	</Drawer>
{/if}

<!-- TODO - grayscale added to prevent flickering of header on page scroll in FireFox - https://stackoverflow.com/questions/30411909/css-transition-flickering-in-firefox -->
<header
	bind:this={header}
	class="border-b-1 fixed left-0 right-0 top-0 z-30 h-20 w-full border-solid bg-white py-3 grayscale-[1%] transition-[border,transform] duration-300 md:h-[5.75rem] lg:h-fit lg:py-6 {headerOnScroll}"
	class:top-8={announcement}
	class:border-grey-200={windowScrollY > 0}
	class:border-transparent={windowScrollY === 0}
>
	<div class="container flex h-full items-center justify-between gap-4 lg:gap-6 xl:gap-12">
		<Button class="lg:hidden" variant="transparent" on:click={toggleMenu} aria-label="Menu">
			<Icon icon={menuIcon} class="text-secondaryBlack w-8 md:w-9 lg:w-10" />
			<span class="sm:text-2xs text-[0.6rem] font-semibold uppercase leading-4 tracking-[0.15rem]">Menu</span>
		</Button>
		<a
			href="/"
			class="absolute bottom-3 left-0 right-0 top-3 m-auto w-[3.5rem] shrink-0 transition-[height,width] duration-200 md:w-[4rem] lg:relative lg:bottom-[initial] lg:top-[initial] {windowScrollY ===
			0
				? 'lg:h-20 lg:w-20 xl:h-24 xl:w-24'
				: 'lg:h-16 lg:w-16'}"
		>
			<StaticPicture alt="Logo {appName}" image={logo} class="w-full" loading="eager" width={96} height={96} />
		</a>
		{#if menu.length > 0}
			<DesktopMenu {menu} />
		{/if}
		<div class="inline-flex h-9 gap-2 pt-1 md:pt-0">
			<Link
				aria-label="Krabice"
				href="/{cartPathMap[TENANT_LOCALE]}"
				asButton
				class="relative"
				iconClass="w-4 absolute md:relative left-0 right-0 mx-auto"
				icon={basket}
				variant="secondaryIcon"
			>
				<span class="hidden text-[.9rem] md:block lg:text-base">Krabice</span>
				{#if $cartStore.loaded && $cartStore.order.products.length > 0}
					<span
						class="bg-primaryRed text-2xs absolute right-0 top-[1.625rem] flex h-4 w-4 items-center justify-center rounded-full text-white md:top-[1.6875rem] md:h-[1.125rem] md:w-[1.125rem]"
						class:animate-flash={$showHeaderStore}
					>
						{$cartStore.order.products.length}
					</span>
				{/if}
			</Link>
			<!-- TODO - login a registrace není součástí MVP -->
			<!-- <Link
				href="/{signUpPathMap[TENANT_LOCALE]}"
				variant="secondaryIcon"
				class="relative"
				icon={person}
				iconClass="absolute md:hidden top-0 bottom-0"
				asButton
			>
				<span class="group-hover:text-primaryRed transition-colors duration-300 hidden md:inline-block">Přihlášení</span>
			</Link> -->
		</div>
	</div>
</header>
