<script lang="ts">
	import { onMount } from "svelte";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { PriceLevel } from "../../../core/schema/PriceLevel.js";
	import { cartStore } from "../stores/cart/cartStore.js";
	import { getPriceOfOrder } from "../../../core/schema/utils/getPriceOfOrder.js";
	import type { OrderPrices } from "../../../core/schema/OrderPrices.js";

	export let hasPanel = false;
	export let isCart = false;

	const { COOKIES } = getGlobalSettings();

	$: price = getPriceOfOrder($cartStore.order, PriceLevel.Basic);

	function toggleBottomCartClass(price: OrderPrices, hasPanel: boolean): void {
		const badge = document.getElementById("cookiescript_badge");
		if (badge) {
			badge.classList.toggle("bottom-cart", price.totalPrice.withTax > 0 && hasPanel);
		}
	}

	function toggleInjectedCartClass(price: OrderPrices, hasPanel: boolean): void {
		const injected = document.getElementById("cookiescript_injected");
		if (injected) {
			injected.classList.toggle("injected-cart", price.totalPrice.withTax > 0 && hasPanel);
		}
	}

	function updateClasses(hasPanel: boolean): void {
		const badge = document.getElementById("cookiescript_badge");
		if (badge) {
			badge.classList.toggle("bottom-cart", hasPanel);
		}
		const injected = document.getElementById("cookiescript_injected");
		if (injected) {
			injected.classList.toggle("injected-cart", hasPanel);
		}

		if (isCart) {
			toggleBottomCartClass(price, hasPanel);
			toggleInjectedCartClass(price, hasPanel);
		}
	}

	let loaded = false;
	function loadScript(): void {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.charset = "UTF-8";
		script.src = `https://cdn.cookie-script.com/s/${COOKIES}.js`;
		script.fetchPriority = "low";
		document.head.appendChild(script);

		function onLoad(): void {
			setTimeout(() => {
				updateClasses(hasPanel);

				const observer = new MutationObserver(() => {
					const injected = document.getElementById("cookiescript_injected");
					if (injected) {
						injected.classList.toggle("injected-cart", hasPanel);
					}
				});
				observer.observe(document.body, { childList: true });
				if (isCart) {
					const observer = new MutationObserver(() => {
						toggleBottomCartClass(price, hasPanel);
						toggleInjectedCartClass(price, hasPanel);
					});
					observer.observe(document.body, { childList: true });
				}

				loaded = true;
			});
			script.removeEventListener("load", onLoad);
		}
		script.addEventListener("load", onLoad);
	}

	onMount(() => {
		loadScript();
	});

	$: {
		if (loaded) {
			updateClasses(hasPanel);
		}
	}

	$: {
		if (isCart) {
			toggleBottomCartClass(price, hasPanel);
			toggleInjectedCartClass(price, hasPanel);
		}
	}

	/* eslint-disable svelte/no-at-html-tags */
</script>

<style lang="postcss">
	:global(#cookiescript_badge) {
		z-index: 40 !important;
	}
	:global(.bottom-cart) {
		bottom: 5rem !important;
		box-shadow: 0 0 0 transparent !important;

		@media screen and (min-width: 640px) {
			bottom: 4.25rem !important;
		}
	}
	:global(.injected-cart) {
		bottom: 6rem !important;
		max-height: calc(100dvh - 7rem) !important;

		@media screen and (min-width: 640px) {
			bottom: 5.25rem !important;
			max-height: calc(100dvh - 6.25rem) !important;
		}
	}
</style>
